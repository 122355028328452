import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import useLocalStorage from '../../hooks/useLocalStorage';
import usePrivacyLink from '../../hooks/usePrivacyLink';

import Button from '../ui/button';

const isCookieBannerSetKey = 'isCookieBannerSet';

const Styles = styled.div`
  height: 80px;
  width: 75%;  

  position: fixed;
  bottom: 120px;
  left: 0;
  z-index: 4;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 2em;

  background-color: ${p => p.theme.colors.background};

  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  box-shadow: ${p => p.theme.shadow.base};

  a {
    color: ${p => p.theme.colors.zomaLight};
  }

  @media(min-width: ${p => p.theme.breakpoints.l}) {
    @keyframes appear {
      0%   { transform: translateX(-100%); }
      100% { transform: translateX(0); }
    } 
    animation: appear 600ms ease-out;    
  }

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    width: 100%;
    bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 8rem;
    padding: 0 1em;
  }
`;

const TextArea = styled.div`
  display: flex;
  &>* {
    margin-right: 0.5rem;
  }

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    flex-direction: column;
    margin-right: 0;
    &>* {
      margin-bottom: 0.2rem;
    }
  }
`;

const Text = styled.p`
`;

export default () => {
  const data = useStaticQuery(graphql`
    query CookieQuery {
      site {
        siteMetadata {
          translations {
            cookieText
            moreCookieText
          }
        }
      }
    }
  `);

  const {
    cookieText, moreCookieText,
  } = data.site.siteMetadata.translations;

  const privacyLink = usePrivacyLink();

  const { value: isCookieSaved, set: setIsCookieSaved } = useLocalStorage(isCookieBannerSetKey);

  return !isCookieSaved ? (
    <Styles>
      <TextArea>
        <Text>{cookieText}</Text>
        <a href={privacyLink}>{moreCookieText}</a>
      </TextArea>
      <Button onClick={() => setIsCookieSaved(true)} small rounded>OK</Button>
    </Styles>
  ) : null;
};
