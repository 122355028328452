import React from 'react';

export default (key) => {
  let defaultValue = null;
  if (typeof window !== 'undefined') {
    defaultValue = window.localStorage.getItem(key);
  }

  const [value, setValue] = React.useState(defaultValue);

  const set = (val = true) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, val);
      setValue(val);
    }
  };

  return { value, set };
};
