const zoma = '#ED1D24';

export default {
  colors: {
    zoma,
    zoma25: 'rgba(237, 29, 36, 0.25)',
    zomaLight: '#ff8286',
    background: '#fff',
    lightBackround: '#fcfcfc',
    text: '#333',
    overlay25: 'rgba(0, 0, 0, 0.25)',
    overlay50: 'rgba(0, 0, 0, 0.50)',
    overlay75: 'rgba(0, 0, 0, 0.75)',
  },
  fonts: {
    base: 'Roboto, "sans-serif"',
    weights: {
      regular: 400,
      bold: 700,
      black: 900,
    },
  },
  layout: {
    contentPaddingDesktop: '128px',
    contentPaddingTablet: '64px',
    contentPaddingMobile: '24px',
  },
  breakpoints: {
    s: '576px',
    m: '768px',
    l: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
  shadow: {
    base: '0 0 14px 0 rgba(0, 0, 0, 0.15)',
    strong: '0 0 28px 0 rgba(0, 0, 0, 0.15)',
    strongBottom: '0 4px 4px 0 rgba(0, 0, 0, 0.05)',
    strongTop: '0 -4px 4px 0 rgba(0, 0, 0, 0.05)',
    dropShadow: 'drop-shadow(-2px 8px 4px rgba(0, 0, 0, 0.15))',
    textShadow: '1px 1px rgba(0, 0, 0, 0.50)',
  },
  animation: {
    pageTransitionDuration: 0.4,
    pageTransitionColor: zoma,
  },
};
