import React, { useState } from 'react';
import styled from 'styled-components';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import BurgerImage from '../../../../assets/svg/burger.svg';
import CloseImage from '../../../../assets/svg/close.svg';

import PageTransitionLink from '../../ui/pageTransitionLink';

const BurgerStyles = styled.div`
  display: none;
  width: 24px;
  height: 24px;

  img, svg {
    width: 24px;
    height: 24px;
  }

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    display: block;
  }

  path {
    fill: ${p => (p.transparent ? p.theme.colors.background : p.theme.colors.text)};
  }
`;

const burgerAnimationTime = 100;
const burgerMenuAnimationTime = 200;

const CloseButton = styled(CloseImage)`
  transition: all ${() => burgerAnimationTime}ms ease-out;

  &.burger-enter {
    transform: rotate(-45deg) scale(0);
  }
  &.burger-enter-active {
    transform: rotate(0deg) scale(1);
  }
  &.burger-exit {
    transform: rotate(0) scale(1);
  }
  &.burger-exit-active {
    transform: rotate(-45deg) scale(0);
  }
`;

const BurgerButton = styled(BurgerImage)`
  transition: all ${() => burgerAnimationTime}ms ease-out;

  &.burger-enter {
    transform: scaleY(0);
  }
  &.burger-enter-active {
    transform: scaleY(1);
  }
  &.burger-exit {
    transform: scaleY(1);
  }
  &.burger-exit-active {
    transform: scaleY(0);
  }
`;

const BurgerNavigation = styled.nav`
  position: absolute;
  z-index: 3;
  top: 80px;
  left: 0;
  width: 100%;

  text-align: center;

  background-color: ${p => p.theme.colors.background};

  transition: transform ${() => burgerMenuAnimationTime}ms ease-out;
  transform-origin: top;
  transition-delay: 1ms; // Safari fix

  &.burgerMenu-enter {
    transform: scaleY(0);
  }
  &.burgerMenu-enter-active {
    transform: scaleY(1);
  }
  &.burgerMenu-exit {
    transform: scaleY(1);
  }
  &.burgerMenu-exit-active {
    transform: scaleY(0);
  }

  box-shadow: ${p => p.theme.shadow.strongBottom};
`;

const BurgerNavigationList = styled.ul`
  width: 100%;
`;

const BurgerNavigationItem = styled.li`
  a {
    font-weight: ${p => p.theme.fonts.weights.black};
    font-size: 1.2rem;
  }

  a.activeNavItemLink {
    color: ${p => p.theme.colors.zoma};
  }
  
  margin: 32px;
`;

export default ({ items, transparent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const styleActiveLink = (linkProps) => (linkProps.isPartiallyCurrent ? { className: 'activeNavItemLink' } : {});

  return (
    <>
      <BurgerStyles transparent={transparent}>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <SwitchTransition mode="out-in">
            <CSSTransition key={isOpen} in={isOpen} timeout={burgerAnimationTime} classNames="burger">
              {() => (isOpen ? <CloseButton /> : <BurgerButton />)}
            </CSSTransition>
          </SwitchTransition>
        </button>
      </BurgerStyles>
      <CSSTransition
        in={isOpen}
        timeout={burgerMenuAnimationTime}
        classNames="burgerMenu"
        mountOnEnter
        unmountOnExit
      >
        {() => (
          <BurgerNavigation>
            <BurgerNavigationList>
              {items.map(item => (
                <BurgerNavigationItem key={item.title}>
                  <PageTransitionLink
                    to={item.link}
                    getProps={styleActiveLink}
                  >
                    {item.title}
                  </PageTransitionLink>
                </BurgerNavigationItem>
              ))}
            </BurgerNavigationList>
          </BurgerNavigation>
        )}
      </CSSTransition>
    </>
  );
};
