import React from 'react';
import styled, { css } from 'styled-components';

import useShouldAnimateOnHover from '../../../hooks/useShouldAnimateOnHover';

import PageTransitionLink from '../../ui/pageTransitionLink';

const navItemTransitionTime = 0.2;

const Navigation = styled.nav`
  margin-left: 64px;

  @media(max-width: ${p => p.theme.breakpoints.l}) {
    margin-left: 16px;  
  }
`;

const NavigationList = styled.ul`
  display: flex;
  margin: 0;
`;

const NavigationItem = styled.li`
  margin: 8px;

  a {    
    text-decoration: none;
    padding: 16px;
    font-size: 1.2rem;
    font-weight: ${props => props.theme.fonts.weights.black};

    position: relative;
    z-index: 1;
  }

  a {    
    color: ${p => (p.transparent ? p.theme.colors.background : p.theme.colors.text)};
    text-shadow: ${p => (p.transparent ? p.theme.shadow.textShadow : 'none')};
  }

  a.activeNavItemLink {
    color: ${p => p.theme.colors.zoma};
  }

  ${p => p.shouldAnimateListItems && css`
    a {
      transition: color ${() => navItemTransitionTime}s ease-out;
    }
    
    a:hover {
      color: ${props => props.theme.colors.background};
      text-shadow: none;
    }

    a:after {
      transition: all ${() => navItemTransitionTime}s ease-out;

      display: block;
      content: '';    
      background: ${props => props.theme.colors.zoma};

      z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      visibility: none;
      opacity: 0;
      transform: scale(0);
      border-radius: 100%;
    }

    a:hover:after {    
      visibility: visible;
      opacity: 1;
      transform: scale(1);
      border-radius: 0;
    }
  `}

  @media(max-width: ${p => p.theme.breakpoints.l}) {
    margin: 4px;
  }

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    display: none;
  }
`;

export default ({ navItems, transparent }) => {
  const shouldAnimate = useShouldAnimateOnHover();

  const styleActiveLink = (linkProps) => (linkProps.isPartiallyCurrent ? { className: 'activeNavItemLink' } : {});

  return (
    <Navigation>
      <NavigationList>
        {navItems.map(item => (
          <NavigationItem key={item.link} shouldAnimateListItems={shouldAnimate} transparent={transparent}>
            <PageTransitionLink
              to={item.link}
              getProps={styleActiveLink}
            >
              {item.title}
            </PageTransitionLink>
          </NavigationItem>
        ))}
      </NavigationList>
    </Navigation>
  );
};
