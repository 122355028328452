import React from 'react';
import styled, { css } from 'styled-components';

import useShouldAnimateOnHover from '../../hooks/useShouldAnimateOnHover';

import PageLink from './pageTransitionLink';

const Styles = styled.button`
  padding: ${p => (p.small ? '1rem' : '1.5rem')} 3rem;
  color: ${p => p.theme.colors.background};
  background-color: ${p => (p.disabled ? p.theme.colors.zoma25 : p.theme.colors.zoma)};

  box-shadow: ${p => p.theme.shadow.base};  

  ${p => (p.rounded ? css`
    border-radius: 12px;
  ` : '')}

  ${p => p.shouldAnimate && !p.disabled && css`
    transition: all 0.3s ease-out;
    &:hover {
      transform: scale(1.1);
      box-shadow: ${() => p.theme.shadow.strong};
    }
  `}

  @media(max-width: ${p => p.theme.breakpoints.l}) {
    padding: 1.2rem 2rem;
  }

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    padding: 1rem 1.8rem;
  }
`;

export default ({
  link, onClick, children, className, disabled, small, rounded,
}) => {
  const shouldAnimate = useShouldAnimateOnHover();

  return (
    link
      ? <PageLink className={className} to={link}><Styles disabled={disabled} shouldAnimate={shouldAnimate} small={small} rounded={rounded}>{children}</Styles></PageLink>
      : <Styles disabled={disabled} onClick={onClick} className={className} shouldAnimate={shouldAnimate} small={small} rounded={rounded}>{children}</Styles>
  );
};
