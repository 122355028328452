import privacyHu from '../../assets/pdf/zoma_pannonia_adatkezeles.pdf';
import privacySk from '../../assets/pdf/zoma_slovakia_privacy.pdf';

export default () => {
  // eslint-disable-next-line prefer-destructuring
  const GATSBY_LANGUAGE = process.env.GATSBY_LANGUAGE;
  if (!GATSBY_LANGUAGE) throw new Error('No cms url env var found!');

  console.log('GATSBY_LANGUAGE: ', GATSBY_LANGUAGE);

  return GATSBY_LANGUAGE === 'sk' ? privacySk : privacyHu;
};
