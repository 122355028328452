/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable func-names */
export const browsers = {
  ie: 'ie',
  safari: 'safari',
  unknown: 'unknown',
};

export default () => {
  if (typeof window === 'undefined') return browsers.unknown;
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; }(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification)));
  // Internet Explorer 6-11
  const isIE = /* @cc_on!@ */false || !!document.documentMode;

  if (isSafari) return browsers.safari;
  if (isIE) return browsers.ie;

  return browsers.unknown;
};

export const isTouchDevice = () => {
  if (typeof window === 'undefined') return true;

  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};
