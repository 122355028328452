/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, createGlobalStyle, css } from 'styled-components';

import theme from '../../theme';

import Header from './header';
import Footer from './footer';

import FullWidthView from '../ui/fullWidthView';
import CookieBanner from '../composite/cookieBanner';

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  body>div, body>div>div {
    width: 100%;
    height: 100%;
  }

  body>div>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  header, footer {
    flex: none;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  * {
    font-family: Roboto, sans-serif;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    list-style: none;
  }

  button {
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }
`;

const Main = styled(FullWidthView)`
  display: block;
  padding-top: 32px;
  background-color: ${p => p.theme.colors.lightBackround};
  flex: auto;

  ${p => p.noMainMargins && css`
    margin: 0 !important;
    padding: 0 !important;
  `}

  ${p => p.noMainMarginsMobile && css`
    @media(max-width: ${p => p.theme.breakpoints.s}) {
      margin: 0 !important;
      padding: 0 !important;
    }    
  `}
`;

const Layout = ({
  children, noMainMargins, noMainMarginsMobile, transparent,
}) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Header transparent={transparent} />
    <Main
      as="main"
      noMainMargins={noMainMargins}
      noMainMarginsMobile={noMainMarginsMobile}
    >
      {children}

    </Main>
    <CookieBanner />
    <Footer transparent={transparent} />
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
