// import React from 'react';
// import AniLink from 'gatsby-plugin-transition-link/AniLink';

// import theme from '../../theme/index';

// export default ({ children, getProps, to }) => (
//   <AniLink
//     paintDrip
//     duration={theme.animation.pageTransitionDuration}
//     hex={theme.animation.pageTransitionColor}
//     to={to}
//     getProps={getProps}
//   >
//     {children}
//   </AniLink>
// );


import React from 'react';
import { Link } from 'gatsby';

export default ({ children, getProps, to }) => (
  <Link
    to={to}
    getProps={getProps}
  >
    {children}
  </Link>
);
