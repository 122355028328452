import { useStaticQuery, graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import LogoImage from '../../../../assets/svg/logo.svg';

import getBrowser, { browsers, isTouchDevice } from '../../../helpers/browser';

import FullWidthView from '../../ui/fullWidthView';
import DesktopNavigation from './desktopNav';
import MobileNavigation from './mobileNav';
import PageTransitionLink from '../../ui/pageTransitionLink';

const HeaderStyles = styled.header`
  position: relative;
  z-index: 3;
  min-height: 120px;
  background-color: ${p => (p.transparent ? 'transparent' : p.theme.colors.background)};
  box-shadow: ${p => (p.transparent ? 'none' : p.theme.shadow.strongBottom)};

  display: flex;
  align-items: center;

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    min-height: 80px;
  }
`;

const HeaderContent = styled(FullWidthView)`
  position: relative;
  z-index: 0;
  height: 100%;

  margin: 0 auto;
  display: flex;
  align-items: center;

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    justify-content: space-between;
  }
`;

const Logo = styled.div`
  svg {
    width: 100px;
    height: 100px;

    ${(p) => p.shouldAnimateLogo && css`
      filter: none;
        
      transition: all 0.2s ease-out;
      &:hover {
        transform: translate(2px, -2px);
        filter: ${props => props.theme.shadow.dropShadow};
      }
    `}

    @media(max-width: ${p => p.theme.breakpoints.m}) {
      width: 60px;
      height: 60px;
    }
  }
`;

const Header = ({ transparent }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          translations {
            about
            products
            contact
          }
        }
      }
    }
  `);

  const {
    about,
    products,
    contact,
  } = data.site.siteMetadata.translations;

  const navItems = [
    {
      title: about,
      link: '/about',
    },
    {
      title: products,
      link: '/categories',
    },
    {
      title: contact,
      link: '/contact',
    },
  ];

  const [shouldAnimateLogo, setShouldAnimateLogo] = useState(false);

  useEffect(() => {
    const isDesktop = !isTouchDevice();
    const browser = getBrowser();
    setShouldAnimateLogo(browser !== browsers.ie && browser !== browsers.safari && isDesktop);
  }, []);

  return (
    <HeaderStyles transparent={transparent}>
      <HeaderContent>

        <PageTransitionLink to="/">
          <Logo shouldAnimateLogo={shouldAnimateLogo}>
            <LogoImage />
          </Logo>
        </PageTransitionLink>

        <DesktopNavigation navItems={navItems} transparent={transparent} />
        <MobileNavigation items={navItems} transparent={transparent} />

      </HeaderContent>
    </HeaderStyles>
  );
};

export default Header;
