import React from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import FullWidthView from '../ui/fullWidthView';
import usePrivacyLink from '../../hooks/usePrivacyLink';

const Footer = styled(FullWidthView)`
  height: 80px;
  position: relative;
  z-index: 3;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  text-align: center;

  box-shadow: ${p => (p.transparent ? 'none' : p.theme.shadow.strongTop)};
  background-color: ${p => (p.transparent ? 'transparent' : p.theme.colors.backround)};

  p, a {
    margin: 4px;
    ${p => p.transparent && css`
      color: ${p.theme.colors.background};
      text-shadow: ${p.theme.shadow.textShadow};
    `}
  }

  a:hover {
    color: ${p => p.theme.colors.zoma};
  }
`;

const CopyrightArea = styled.div`
  display: flex;
  &>*:first-child {
    margin-right: 0.1rem;
  }

  &>p:first-child {
    font-weight: ${p => p.theme.fonts.weights.bold};
  }
`;

export default ({ transparent }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          translations {
            companyName
            privacy
          }
        }
      }
    }
  `);

  const {
    companyName,
    privacy: privacyTitle,
  } = data.site.siteMetadata.translations;

  const privacyLink = usePrivacyLink();

  console.log(privacyLink);

  return (
    <Footer as="footer" transparent={transparent}>
      <CopyrightArea>
        <p>{`© ${new Date().getFullYear()}`}</p>
        <p>{companyName}</p>
      </CopyrightArea>
      <a href={privacyLink}>{privacyTitle}</a>
    </Footer>
  );
};
