/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ title }) {
  const { site: { siteMetadata } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            translations {
              description
              lang
              keywords
            }
          }
        }
      }
    `,
  );

  const metaDescription = siteMetadata.translations.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: siteMetadata.translations.lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: siteMetadata.translations.keywords,
        },
      ]}
    >
      <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,900&display=swap" rel="stylesheet" />
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SEO;
