import styled from 'styled-components';

export default styled.div`
  width: 100%;
  padding-left: ${props => props.theme.layout.contentPaddingDesktop};
  padding-right: ${props => props.theme.layout.contentPaddingDesktop};

  @media(max-width: ${p => p.theme.breakpoints.l}) {
    padding-left: ${p => p.theme.layout.contentPaddingTablet};
    padding-right: ${p => p.theme.layout.contentPaddingTablet};
  }

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    padding-left: ${p => p.theme.layout.contentPaddingMobile};
    padding-right: ${p => p.theme.layout.contentPaddingMobile};
  }
`;
