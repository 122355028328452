import { useState, useEffect } from 'react';
import { isTouchDevice } from '../helpers/browser';

export default () => {
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => setShouldAnimate(!isTouchDevice()), []);

  return shouldAnimate;
};
